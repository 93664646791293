import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import { Link } from 'gatsby'
import SEO from '../../../components/common/SEO';
import Banner from '../../../components/features/Banner'
import Question from '../../../components/features/Question'
import Video from '../../../components/features/Video'
import Info from '../../../components/features/Info'
import Steps from '../../../components/features/Steps'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Policies {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
  `

export default ({ data }) => {
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=3Ht8bBpOVJ0"],
    }

    const bannerProps = {
        title: 'Workplace Policies',
        subtitle: `It's never been easier to assure your critical policies are being reviewed and acknowledged across your organization with custom assignments, version control, compliance tracking and much more.`,
        featureName: 'workplacePolicies',
        rightButtonText: 'Why Workplace Policies',
        rightButtonSrc: 'Why Workplace Policies?',
        mockupImg: '/features/policies/feature-page-header-policies.png'
    }

    const seo = {
        title: 'Workhub | Free Policy Management Software Host Organize Distribute',
        description: "Our Policy Management Software let's you easily host, manage, track, organize & distribute workplace safety & culture policies. ",
        image: '/features/policies/social-card.jpg',
        // pathname,
        // article
    }

    const questionProps = {
        question: 'Are your policies collecting cobwebs?',
        answer: `Don't worry you aren't alone. Maintaining policies within an organization has been an issue for literally over 100 years. Many companies find it nearly impossible to track readership and maintain versions especially across multiple locations. Many common painpoints such as:`,
        answerBulletList: [
            'Unable to manage versions and notify of new versions',
            'Too hard to motivate staff to read',
            'Hard to track who has read and acknowledged',
        ],
        imageSrc: '/features/sketch/policies-large.jpg',
        imgAlt: 'Cartoon of a stack of dusty old cobwebbed books that are full of Policies'
    }

    const videoProps = {
        videoSrc: 'https://www.youtube.com/watch?v=0kwEjqHY2kw',
        videoImage: '/features/policies/policies-thumbnail.jpg',
        videoStatement: (
            <strong>Workplace Policy Component</strong>)
    }

    const infoProps = {
        infoList: [
            {
                mockupImage: '/features/policies/policies-admin-large-bg-1.jpg',
                infoTitle: 'Administer Your Policies with Ease and Flexibility',
                infoSubtitle: `Upload safety policies from your safety manual and organize them just like they appear in your table of contents with section numbers and versions.`,
                infoBullets: [
                    {
                        name: 'Version Control:',
                        description: `We've built an awesome version control system so you can make changes to individual policies and roll them out to workers without resetting the entire manual.`
                    },
                    {
                        name: 'Link Forms to Policies:',
                        description: `Got an inspection policy? Make sure the inspection form is linked to the policy so your worker has access to it while reading the policy. No more forms stuffed at the back of your manual.`,
                    },
                    {
                        name: 'Upload Current Policies:',
                        description: `Your policies will be sorted just like the appear in your table of contents. Easily deploy all or parts of manuals to your workers as needed.`,
                    }
                ],
            },
            {
                mockupImage: '/features/policies/policies-admin-large-bg-2.jpg',
                infoTitle: 'Track Acknowledgement and Compliance',
                infoSubtitle: `Rather than having workers sign off on having read the whole manual (they never do) workers will be required to study each policy independently and sign off on each one.`,
                infoBullets: [
                    {
                        name: 'Policy Acknowledgements:',
                        description: `Workers must spend a pre-set(you choose) amount of time on each policy before they can acknowledge it and sign off.`
                    },
                    {
                        name: 'Track Compliance:',
                        description: `Track acknowledgement compliance for all your workers and submit reports to management on the status of all new safety policies.`,
                    },
                    {
                        name: 'Get Feedback:',
                        description: `As workers read your policies they can send feedback directly to your safety team to use as ideas for improvements.`,
                    }
                ],
            }
        ]
    }

    const stepsProps = {
        title: 'Worker Adoption is as easy as 1,2,3',
        subtitle: `Reviewing policies for your workers has never been easier with Workhub's user friendly worker interface.`,
        stepList: [
            {
                title: 'Check the Dashboard',
                subtitle: 'Quickly see pending items that require attention',
                imageSrc: '/features/policies/policies-step-1-upload.JPG'
            },
            {
                title: 'Read the Policy',
                subtitle: `Study the policy for the set time limit then acknowledge or decline`,
                imageSrc: '/features/policies/policies-step-2-assign.JPG'
            },
            {
                title: 'Gain Compliance',
                subtitle: 'Gain complete compliance across your organization',
                imageSrc: '/features/policies/policies-step-3-track.JPG'
            }
        ]
    }

    const featureIndex = [
        {
            title: "Upload",
            para: "Upload policies from your safety manual and organize them as they appear in your table of contents with section numbers and versions.",
        },
        {
            title: "Version Control",
            para: "Make changes to individual policies and roll them out to workers without resetting the entire manual.",
        },
        {
            title: "Link Forms to Policies",
            para: "Link relevant forms to policies so that workers can access them while reading policies. ",
        },
        {
            title: "Upload Current Policies",
            para: "Policies are sorted as they appear in your table of contents. Deploy all or portions of the manuals to workers, as needed.",
        },
        {
            title: "Policy Acknowledgements",
            para: "Select a pre-set amount of time that workers must spend on each policy before they can acknowledge it and sign off.",
        },
        {
            title: "Track Compliance",
            para: "Track acknowledgement compliance for all your workers and submit reports to management on the status of all new safety policies.",
        },
        {
            title: "Feedback",
            para: "As workers read your policies, they can send feedback directly to your safety team to use as ideas for improvements.",
        },
    ]


    return (
        <Layout {...lightboxProps}>
            <SEO {...seo} {...data.site} />
            <Banner {...bannerProps} {...lightboxProps} />
            <Question {...questionProps} />
            <Video {...videoProps} {...lightboxProps} />
            <Info {...infoProps} />
            <Steps {...stepsProps} />

            {/* FEATURE OVERVIEW */}

            <section className="feature-overview-container">
                <h2>Overview</h2>

                <div className="feature-overview-grid" style={{ marginBottom: '60px' }}>
                    {featureIndex.map(item => (
                        <FeatureCards title={item.title} para={item.para} />
                    ))}
                </div>
            </section>



        </Layout>
    )
}